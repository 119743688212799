import React from "react"
import ProjectList from "./ProjectList"
import { useStaticQuery, graphql } from "gatsby"

const getProjects = graphql`
    query {
        projects:allContentfulProject {
            edges {
                node {
                    name
                    slug
                    category
                    contentful_id
                    image {
                        fluid {
                            ...GatsbyContentfulFluid_tracedSVG
                        }
                    }
                    video {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`

const Projects = () => {
  const { projects } = useStaticQuery(getProjects)
  return (
      <ProjectList projects={projects}/>
  )
}

export default Projects
