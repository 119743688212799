import React, { Component } from "react"
import listStyles from '../../css/items.module.css'
import Project from './Project'
import Title from '../Title'

class ProjectList extends Component {
  state = {
    projects: [],
    sortedProjects: [],
  }

  componentDidMount() {
    this.setState({
      projects: this.props.projects.edges,
      sortedProjects: this.props.projects.edges,
    })
  }

  render() {
    return (
      <section className={listStyles.projects}>
        <Title title="" subtitle="projects" />
        <div className={listStyles.center}>
          {this.state.sortedProjects.map(({ node }) => {
            return <Project key={node.contentful_id} project={node} />
          })}
        </div>

      </section>
    )
  }
}

export default ProjectList
